import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import WebApp from '../views/WebApp.vue'
import Login from '../views/Login.vue'
import Profile from '../views/Profile.vue'
import AboutView from '../views/AboutView.vue'
import store  from '../store/index'
import FlashCards from '../views/FlashCards.vue'
import Results from '../views/Results.vue'


const authGuard = (to, from, next) => {
  if (store.state.user) {
    // Se o usuário estiver autenticado, permita a navegação
    next()
  } else {
    // Se o usuário não estiver autenticado, redirecione para a página de login,
    // mas salve a rota para a qual ele estava tentando acessar para redirecioná-lo
    // de volta após o login bem-sucedido
    next({
      path: '/',
      query: { redirect: to.fullPath }
    })
  }
}

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login,
  },
  {
    path: '/webapp',
    name: 'WebApp',
    component: WebApp,
    beforeEnter: authGuard,
    children: [
      {
        path: 'home',
        component: HomeView
      },
      {
        path: 'about',
        component: AboutView
      },
      {
        path: 'profile',
        component: Profile
      },
      {
        path: 'flashcards',
        component: FlashCards
      },
      {
        path: 'results',
        component: Results
      },
    ]
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  base: process.env.BASE_URL,
  routes
})

export default router  