<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="6">
        <v-card class="data-card">
          <v-card-title class="card-title">Total de Cards Estudados</v-card-title>
          <v-card-text class="card-text">{{ totalCards }}</v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card class="data-card">
          <v-card-title class="card-title">Cards Estudados Hoje</v-card-title>
          <v-card-text class="card-text">{{ totalCardsToday }}</v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card class="chart-card">
          <v-card-title class="card-title">Evolução de Cards Estudados / Dia</v-card-title>
          <v-card-text>
            <div id="chart" class="chart"></div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>


<script>
import { ref, computed, onMounted } from 'vue';
import * as echarts from 'echarts';

export default {
  data() {
    return {
      cardsData: [],
    };
  },
  computed: {
    totalCards() {
      return this.cardsData.reduce((total, item) => total + item.cards, 0);
    },
    totalCardsToday() {
      const currentDate = new Date();
      const day = currentDate.getDate();
      const month = currentDate.getMonth() + 1;
      const year = currentDate.getFullYear();
      const currentDateStr = day + "/" + month + "/" + year;

      return this.cardsData.reduce((total, item) => {
        return item.date === currentDateStr ? total + item.cards : total;
      }, 0);    
    },
  },
  mounted() {
    const chartElement = document.getElementById('chart');
    const chartInstance = echarts.init(chartElement);
    const options = {
      xAxis: {
        type: 'category',
        data: this.cardsData.map(item => item.date),
      },
      yAxis: {
        type: 'value',
      },
      series: [{
        data: this.cardsData.map(item => item.cards),
        type: 'line',
      }],
    };
    chartInstance.setOption(options);
  },
  created() {
    // Supondo que você tenha uma ação no Vuex para buscar os dados
    // Exemplo: this.$store.dispatch('fetchUserData');
    // Nesse caso, você precisa chamar essa ação para buscar os dados de cardsData
    const userData = this.$store.getters.userName[0]?.dates;
    if (userData && userData.length > 0) {
      this.cardsData = userData;
    }
  },
};
</script>

<style scoped>
.data-card, .chart-card {
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  transition: transform .3s ease;
  overflow: hidden; /* Para garantir que tudo fique dentro dos cantos arredondados */
}

.card-title {
  font-size: 18px;
  font-weight: bold;
  color: #333;
  text-align: center; /* Centraliza o título para uma aparência mais uniforme */
}

.card-text {
  font-size: 24px;
  color: #666;
  text-align: center; /* Centraliza o texto para destacar os números */
}

.chart {
  width: 100%;
  height: 400px;
}

.data-card:hover, .chart-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 12px 20px rgba(0,0,0,0.2);
}

.v-container {
  margin-top: 20px; /* Adiciona um pouco de espaço no topo para não ficar muito junto à borda da tela */
}

/* Adiciona um pouco mais de espaçamento ao redor dos cards para uma melhor respiração */
.v-row {
  margin-bottom: 20px;
}

.v-col {
  padding: 10px;
}
</style>