<template>
  <v-app>    
    <v-container>
      <div class="sobre">
        <v-row>
          <v-col cols="12">
            <h1 class="display-2">Decks</h1>
          </v-col>
        </v-row>
        <v-expansion-panels>
          <v-expansion-panel v-for="(area, index) in areas" :key="index">
            <v-expansion-panel-header class="area-header">
              <div class="area-title">{{ area.nome }} 
                <v-chip class="custom-chip">{{ area.count }}</v-chip>
                <v-icon @click="toggleExpansion(area)">{{ area.expanded ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
              </div>
              <v-btn :class="{'disabled-btn': !isFirstButton(area, null, null, null)}" @click="logValues(area, null, null, null)" text class="log-btn" :disabled="!isFirstButton(area, null, null, null)">
                <v-icon>mdi-play</v-icon>
              </v-btn>
            </v-expansion-panel-header>
            <v-expansion-panel-content v-show="area.expanded">
              <v-expansion-panels>
                <v-expansion-panel class="ml-0" v-for="(subarea, subareaIndex) in area.subareas" :key="'subarea-' + subareaIndex">
                  <v-expansion-panel-header class="subarea-header">
                    <div class="subarea-title">{{ subarea.nome }}
                      <v-chip class="custom-chip">{{ subarea.count }}</v-chip>
                      <v-icon @click="toggleExpansion(subarea)">{{ subarea.expanded ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                    </div>
                    <v-btn :class="{'disabled-btn': !isFirstButton(area, subarea, null, null)}" @click="logValues(area, subarea, null, null)" text class="log-btn" :disabled="!isFirstButton(area, subarea, null, null)">
                      <v-icon>mdi-play</v-icon>
                    </v-btn>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content v-show="subarea.expanded">
                    <v-expansion-panels>
                      <v-expansion-panel class="ml-0" v-for="(tema, temaIndex) in subarea.temas" :key="'tema-' + temaIndex">
                        <v-expansion-panel-header class="tema-header" @click="toggleExpansion(tema)">
                          <div class="tema-title">{{ tema.nome }}
                            <v-chip class="custom-chip">{{ tema.count }}</v-chip>
                            <v-icon>{{ tema.expanded ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                          </div>
                          <v-btn :class="{'disabled-btn': !isFirstButton(area, subarea, tema, null)}" @click="logValues(area, subarea, tema, null)" text class="log-btn" :disabled="!isFirstButton(area, subarea, tema, null)">
                            <v-icon>mdi-play</v-icon>
                          </v-btn>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content v-show="tema.expanded">
                          <v-expansion-panels>
                            <v-expansion-panel class="ml-0" v-for="(subtema, subtemaIndex) in tema.subtemas" :key="'subtema-' + subtemaIndex" :style="{}">
                              <v-expansion-panel-header class="subtema-header">
                                <div class="subtema-title">{{ subtema.nome }}
                                  <v-chip class="custom-chip">{{ subtema.count }}</v-chip>
                                </div>
                                <v-btn :class="{'disabled-btn': !isFirstButton(area, subarea, tema, subtema)}" @click="logValues(area, subarea, tema, subtema)" text class="log-btn" :disabled="!isFirstButton(area, subarea, tema, subtema)">
                                  <v-icon>mdi-play</v-icon>
                                </v-btn>
                              </v-expansion-panel-header>
                            </v-expansion-panel>
                          </v-expansion-panels>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
    </v-container>
  </v-app>
</template>

<script>
export default {
  data() {
    return {
      // Add any data if needed
    };
  },
  methods: {
    toggleExpansion(item) {
      // Se o item já estiver expandido, recolha apenas esse item
      if (item.expanded) {
        item.expanded = false;
      } else {
        // Caso contrário, aplique a lógica anterior para expandir o item clicado e recolher os outros itens
        item.expanded = true;
        this.areas.forEach(area => {
          if (area !== item) {
            area.expanded = false;
          } else {
            area.expanded = true;
          }
          area.subareas.forEach(subarea => {
            if (subarea !== item) {
              subarea.expanded = false;
            } else {
              subarea.expanded = true;
              area.expanded = true;
            }
            subarea.temas.forEach(tema => {
              if (tema !== item) {
                tema.expanded = false;
              } else {
                tema.expanded = true;
                area.expanded = true;
                subarea.expanded = true;
              }
              tema.subtemas.forEach(subtema => {
                if (subtema !== item) {
                  subtema.expanded = false;
                } else {
                  subtema.expanded = true;
                  area.expanded = true;
                  subarea.expanded = true;
                  tema.expanded = true;
                }
              });
            });
          });
        });
      }
    },
    isFirstButton(area, subarea, tema, subtema) {
      if (this.userDemo === "yes") {
        if (!subarea && !tema && !subtema) {
          return this.areas.indexOf(area) === 0;
        } else if (!tema && !subtema) {
          return area.subareas.indexOf(subarea) === 0;
        } else if (!subtema) {
          return subarea.temas.indexOf(tema) === 0;
        } else {
          return tema.subtemas.indexOf(subtema) === 0;
        }
      } else {
        return true;
      }
    },
    logValues(area, subarea, tema, subtema) {
      if (this.userDemo === "yes" && !this.isFirstButton(area, subarea, tema, subtema)) {
        return;
      }

      const valuesArray = {};

      if (area !== null) {
        valuesArray.area = area.nome;
      }

      if (subarea !== null) {
        valuesArray.subArea = subarea.nome;
      }

      if (tema !== null) {
        valuesArray.tema = tema.nome;
      }

      if (subtema !== null) {
        valuesArray.subTema = subtema.nome;
      }

      console.log("Valores enviados:", valuesArray);

      this.$store.dispatch('generateCards', valuesArray)
        .then(() => {
          this.$router.push('/webapp/FlashCards');
        })
        .catch((error) => {
          console.error("Error submitting data:", error);
          // Handle error scenario here if needed
        });
    }
  },
  computed: {
    areas() {
      return this.$store.getters.areas;
    },
    userDemo: function () {
      // Verifica se this.$store.getters.userName não é undefined ou null
      if (this.$store.getters.userName !== undefined && this.$store.getters.userName !== null) {
        // Verifica se this.$store.getters.userName é um array e possui pelo menos um elemento
        if (Array.isArray(this.$store.getters.userName) && this.$store.getters.userName.length > 0) {
          // Retorna o demo do primeiro elemento do array
          return this.$store.getters.userName[0].demo;
        } else {
          // Trate o caso em que this.$store.getters.userName é um array vazio
          return '';
        }
      } else {
        // Trate o caso em que this.$store.getters.userName é undefined ou null
        return '';
      }
    },
  },
};
</script>

<style scoped>
.area-header, .subarea-header, .tema-header, .subtema-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-radius: 5px;
  transition: background-color 0.3s, box-shadow 0.3s;
}

.area-header {
  background-color: #ffffff;
  font-size: 16px; /* Tamanho maior para a área */
}

.subarea-header {
  background-color: #ffffff;
  font-size: 16px; /* Tamanho ligeiramente menor para a subárea */
  margin-left: 20px; /* Indenta subáreas para diferenciar da área */
}

.tema-header {
  background-color: #ffffff;
  font-size: 14px; /* Tamanho menor para o tema */
  margin-left: 40px; /* Indenta temas para diferenciar da subárea */
}

.subtema-header {
  background-color: #ffffff;
  font-size: 12px; /* Tamanho ainda menor para o subtema */
  margin-left: 60px; /* Indenta subtemas para diferenciar do tema */
}

.log-btn {
  background-color: #005b96; /* Cor principal para o botão */
  color: white;
  padding: 5px;
  width: auto;
  height: auto;
  min-width: auto;
}

.custom-chip {
  background-color: #113146; /* Cor principal para os chips */
  color: white;
  font-size: 12px; /* Define o tamanho da fonte para 12 pixels, por exemplo */
  padding: 4px 4px; /* Define o espaçamento interno do chip */
}

.sobre {
  margin-top: 0px;
}

.disabled-btn {
  pointer-events: none;
  opacity: 0.5;
}
</style>
