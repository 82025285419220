<template>
  <v-main class="custom-background">
    <v-container class="fill-height" fluid>
      <v-row no-gutters align="center" justify="center">
        <v-col xs="12" sm="10" md="8" lg="6" xl="4">
          <v-img
            :max-width="420"
            src="../assets/medhack_logo.png"
            class="mx-auto mb-16"
          ></v-img>

          <!-- Use v-if diretamente no v-alert -->
          <v-alert v-if="error" :value="true"  @click="onDismissed" type="error">
            {{ error.message }}
          </v-alert>

          <v-card class="px-auto px-6 py-8" elevation="2" >
            <v-card-title class="text-center mb-10"                 v-if="!forgotPassword"> <!-- Adiciona a classe text-center para centralizar o título -->
              <span class="display-1 font-weight-bold" style="color: #144178; font-size: 28px;">Acesse sua conta</span> <!-- Adiciona a classe font-weight-bold para tornar o título em negrito -->
          </v-card-title>
          <v-card-title class="text-center mb-10"                v-if="forgotPassword" > <!-- Adiciona a classe text-center para centralizar o título -->
              <span class="display-1 font-weight-bold" style="color: #144178; font-size: 28px;">Redefinição de senha</span> <!-- Adiciona a classe font-weight-bold para tornar o título em negrito -->
          </v-card-title>
            <v-form
              v-model="form"
              @submit.prevent="onSubmit"
            >
            <v-text-field
              v-model="email"
              :readonly="loading"
              :rules="[rules.required, rules.email]"
              class="mt-4 mb-2"
              clearable
              label="Email"
              prepend-inner-icon="mdi-account"
              filled 
              color="primary" 
              solo 
              style="font-family: 'Inter', sans-serif; padding-left: 10px; border-radius: 10px;"  
          ></v-text-field>
          <v-text-field
              v-if="!forgotPassword"
              v-model="password"
              :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
              :readonly="loading"
              :rules="[rules.required, rules.min]"
              :type="show ? 'text' : 'password'"
              clearable
              label="Senha"
              placeholder="Digite sua senha"
              @click:append="show = !show"
              prepend-inner-icon="mdi-lock"
              filled 
              color="primary" 
              solo  
              style="font-family: 'Inter', sans-serif; padding-left: 10px; border-radius: 10px;"  
          ></v-text-field>
                   
              <v-btn
                v-if="!forgotPassword"
                :disabled="!form"
                :loading="loading"
                block
                color="#144178"
                style="height: 48px; font-size: 15px; color: white; border-radius: 5px" 
                type="submit"
                variant="elevated"
                text-color="white"
              >
                Continuar
              </v-btn>

              <v-btn 
                v-if="!forgotPassword"
                block
                justify="center"
                small
                @click="showForgotPassword"
                class="d-flex justify-center mt-5 mb-0"
                style="text-transform: none; text-decoration: underline;" 
                variant="text"

              >
                Redefinir senha
              </v-btn>

            </v-form>
            <v-btn
              v-if="forgotPassword"
              block
              :disabled="!form"
              color="#144178"
              style="height: 48px; font-size: 15px; color: white; border-radius: 0px" 
              @click="confirmForgotPassword"
              rounded
            >
              Confirmar
            </v-btn>

            <v-btn 
                v-if="forgotPassword"
                block
                justify="center"
                small
                @click="forgotPassword = false"
                class="d-flex justify-center mt-5 mb-0"
                style="text-transform: none; text-decoration: underline;" 
                variant="text"

              >
                Voltar
              </v-btn>
          </v-card>
          <!-- Adicione o copy right abaixo do v-card -->
        <v-col cols="12" class="text-center pt-15">
          <span class="grey--text text--darken-3 font-weight-bold caption"> © MedHack 2024</span>
        </v-col>
        </v-col>
      </v-row>
      
      
    </v-container>  
    <ConfirmationDialog :dialogVisible="showConfirmationDialog" @close="closeConfirmationDialog" :email="email" />
    
  </v-main>
</template>

<script>
import ConfirmationDialog from '../components/Shared/PasswordReset.vue';

export default {
  components: {
    ConfirmationDialog,
  },
  data: () => ({
    email: "",
    password: "",
    form: false,
    show: false,
    showConfirmationDialog: false,

    rules: {
      required: value => !!value || 'Campo Obrigátorio.',
      min: v => v.length >= 6 || 'Sua senha deve ter no mínimo 6 caracteres.',
      email: value => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return pattern.test(value) || 'E-mail Inválido.'
      },
    },
    forgotPassword: false,
    showForgot: false,
  }),

  methods: {
    onSubmit() {
      if (!this.form) return;

      let payload = {
        email: this.email,
        password: this.password,
      };
      this.$store.dispatch('signUserIn', payload);
    },
    onDismissed() {
      this.$store.dispatch('clearError');
    },
    showForgotPassword() {
      this.forgotPassword = true;
      this.showForgot = true;
    },
    confirmForgotPassword() {
      this.$store.dispatch('forgotPassword', {email: this.email})
          
      // For example, sending an email or redirecting to a new page
      this.showConfirmationDialog = true;

      // Then reset the fields and show the original form
     // this.email = '';
      this.password = '';
      this.forgotPassword = false;
      this.showForgot = false;
    },
    closeConfirmationDialog() {
      // Close the confirmation dialog
      this.showConfirmationDialog = false;
    },
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    error() {
      return this.$store.getters.error;
    },
    loading() {
      return this.$store.getters.loading;
    },
  },
  watch: {
    user(value) {
      if (value !== null && value !== undefined) {
        if (value.email) {
          let userName = value.email.split('@')[0];
          if (userName == "eventcenter") {
            this.$router.push('/webapp/home');
          } else {
            this.$router.push('/webapp/home');
          }
        } else {
          this.$router.push('/webapp/home');
        }
      }
    },
  },
};
</script>


<style>
.custom-background{
  background: rgb(235,235,235);
background: -moz-linear-gradient(90deg, rgba(235,235,235,1) 0%, rgba(235,235,235,1) 100%);
background: -webkit-linear-gradient(90deg, rgba(235,235,235,1) 0%, rgba(235,235,235,1) 100%);
background: linear-gradient(90deg, rgba(235,235,235,1) 0%, rgba(235,235,235,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ebebeb",endColorstr="#ebebeb",GradientType=1);
}
</style>
