<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" sm="8" md="6">
        <v-card elevation="5">
          <v-card-text>
            <v-row align="center">
              <v-col cols="12" class="text-center">
                <v-icon size="100" color="primary">mdi-account-circle</v-icon>
                <v-card-title class="text-center">Editar Usuário</v-card-title>
              </v-col>
              <v-col cols="12">
                <v-row>
                  <v-col cols="6">
                    <v-text-field v-model="firstName" label="Nome" prepend-icon="mdi-account"></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field v-model="lastName" label="Sobrenome"></v-text-field>
                  </v-col>
                </v-row>
                <v-text-field v-model="userEmail" label="Email" prepend-icon="mdi-email" readonly class="disabled"></v-text-field>
                <v-text-field v-model="userPhone" label="Telefone" prepend-icon="mdi-phone"></v-text-field>
                <v-row>
                  <v-col cols="6">
                    <v-text-field v-model="userAge" label="Idade" type="number" prepend-icon="mdi-human-child"></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-select v-model="userGender" :items="genderOptions" label="Gênero"></v-select>
                  </v-col>
                </v-row>
                <!-- Adicione outros campos conforme necessário -->
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <small class="text-center">Os dados inseridos estão protegidos conforme as normas da LGPD.</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="saveChanges">Salvar</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { ref } from 'vue';

export default {
  data() {
    return {
      firstName: '',
      lastName: '',
      userEmail: '',
      userPhone: '',
      userAge: '',
      userGender: '',
      genderOptions: ['Masculino', 'Feminino', 'Outro']
    };
  },
  computed: {
    userData() {
      return [
        { key: 'firstName', value: this.firstName },
        { key: 'lastName', value: this.lastName },
        { key: 'userEmail', value: this.userEmail },
        { key: 'userPhone', value: this.userPhone },
        { key: 'userAge', value: this.userAge },
        { key: 'userGender', value: this.userGender }
      ];
    }
  },
  created() {
    // Inicialize os dados do usuário quando o componente for criado
    const userData = this.$store.getters.userName;
    if (userData && userData.length > 0) {
      const user = userData[0];
      this.firstName = user.firstName || '';
      this.lastName = user.lastName || '';
      this.userEmail = user.userEmail || '';
      this.userPhone = user.userPhone || '';
      this.userAge = user.userAge || '';
      this.userGender = user.userGender || '';
    }
  },
  
  methods: {
    saveChanges() {
      const userPayload = {
        name: this.firstName,
        lastName: this.lastName,
        email: this.userEmail,
        phone: this.userPhone,
        age: this.userAge,
        gender: this.userGender
      };
      console.log(userPayload); 
      this.$store.dispatch('saveProfile', userPayload)
        .then(() => {
          console.log("Submission successful. Reloading...");
          // Reload the window after successful submission
          setTimeout(() => {
           // window.location.reload();
            this.$router.push('/webapp/profile');
          }, 1000); // 2000 milliseconds = 2 seconds
        })
      
      // Exemplo: Exibindo os dados do usuário no console
      // Aqui você pode enviar os dados do usuário para o servidor
      // Ou realizar qualquer ação necessária com os dados do usuário
    }
  },
  
};
</script>

<style scoped>
.v-card {
  max-width: 600px;
  margin: 0 auto;
}
</style>
