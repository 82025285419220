import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import { loadFonts } from './plugins/webfontloader';
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getFirestore } from 'firebase/firestore'; // Adicionado
import { getAuth } from 'firebase/auth';
import firebase from 'firebase/app';
import 'firebase/auth';

loadFonts();

const firebaseConfig = {
  apiKey: "AIzaSyD7OmnYnFIxl0Q72qtPQ9Txfk-S5eqPKQw",
  authDomain: "medhack-82198.firebaseapp.com",
  projectId: "medhack-82198",
  storageBucket: "medhack-82198.appspot.com",
  messagingSenderId: "397846211599",
  appId: "1:397846211599:web:051c6b16a41b6f2b89877a",
  measurementId: "G-H9ZFKBT3G4"
};

// Inicialize o Firebase
const firebaseApp = initializeApp(firebaseConfig);
const analytics = getAnalytics(firebaseApp);
const auth = getAuth(firebaseApp); // Obtenha a instância de autenticação do Firebase
const db = getFirestore(firebaseApp); // Obtenha a instância do Firestore



let app
// Exporte o objeto auth para ser usado em outros lugares do aplicativo
export { auth, db};


// Integração com a autenticação do Firebase
auth.onAuthStateChanged(user => {
  if (!app) {
    const app = createApp(App);
    // Fornece a instância do aplicativo Firebase para os componentes Vue
app.provide('firebaseApp', firebaseApp);

// Fornece a instância do Firebase Analytics para os componentes Vue
app.provide('analytics', analytics);
    app.use(router);
    app.use(store);
    app.use(vuetify);
    app.mount('#app');
  }
  if (user) {
    store.dispatch('autoSignIn', user);
  }
});