<template>
  <v-dialog v-model="localDialogVisible" max-width="500">
    <v-card class="custom-dialog">
      <v-card-title :style="{ color: 'white', backgroundColor: '#144178' }">Alterar Senha</v-card-title>
      <v-card-text>
        Um email foi enviado para <b>{{ email }}</b>. 
        <br>Siga as instruções enviadas para redefinição de senha.
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" @click="closeDialog">Fechar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref, watch } from 'vue';

export default {
  props: {
    dialogVisible: Boolean,
    email: String,
  },
  setup(props, { emit }) {
    const localDialogVisible = ref(props.dialogVisible);

    watch(() => props.dialogVisible, (newValue) => {
      localDialogVisible.value = newValue;
    });

    const closeDialog = () => {
      localDialogVisible.value = false;
      emit('update:dialogVisible', false);
    };

    return { localDialogVisible, closeDialog };
  },
};
</script>

<style>
.custom-dialog {
  border-radius: 10px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
}

.primary {
  background-color: #1976D2;
}
</style>
