<template>
  <div class="sobre px-0 pt-2" >
    <v-container fluid fill-height>

      <div class="d-flex justify-center"> <!-- Added flex container for centering -->
        <v-row justify="center">
          <v-col cols="12" sm="8" md="6" lg="12" class="text-center mx-auto lg:mx-0 pt-0"   style="max-width: 500px;">
            <v-progress-linear 
              class="progress-bar mt-0"
              height="10"
              :model-value="progressPercentage"
              color="primary"
              style="max-width: 500px;" 
            ></v-progress-linear>

            <v-card v-if="!isFinished" class="flashcard mx-auto" style="max-width: 500px; max-height: 72vh; overflow: auto; min-height: 72vh; display: flex; align-items: center;">
              <v-card-text class="flashcard-content" style="width: 100%;">
                <template v-if="!showAnswer" class>
                  <!-- Exibindo a imagem da pergunta, se estiver presente -->
                  <div class="text-h5" :style="{ 'white-space': 'pre-line' }">{{ currentFlashcard.question }}</div>
                  <v-img v-if="currentFlashcard.questionImage" :src="currentFlashcard.questionImage" style="max-width: 60%; max-height: 50%; display: block; margin: 0 auto;"></v-img>

                </template>
                <template v-else>
                  <!-- <div class="text-h5">Pergunta</div> -->
                  <div class="text-h5-question mb-15" :style="{ 'white-space': 'pre-line' }">{{ currentFlashcard.question }}</div>
                  <!-- Utilizando a transição fade-transition -->
                  <v-divider class="my-4"></v-divider>
                  <!-- Exibindo a imagem da resposta, se estiver presente -->
                  <!-- <div class="text-h5">Resposta</div> -->
                  <div class="text-h5 mt-15" :style="{ 'white-space': 'pre-line' }">{{ currentFlashcard.answer }}</div>
                  <v-img class="mt-5" v-if="currentFlashcard.answerImage" :src="currentFlashcard.answerImage" style="max-width: 60%; max-height: 50%; display: block; margin: 0 auto;"></v-img>
                </template>
              </v-card-text>
            </v-card>

            <v-card v-else class="flashcard mx-auto" style="max-width: 500px; max-height: 72vh; overflow: auto; min-height: 72vh; display: flex; align-items: center;">
              <v-card-text class="flashcard-content">
                <div class="text-h5">Revisão Concluída!</div>
                <v-card-actions class="d-flex justify-center" v-if="!showAnswer">
                  <v-btn small color="primary" @click="saveCards">Finalizar</v-btn>
                </v-card-actions>
              </v-card-text>
            </v-card>

            <v-card-actions v-if="showAnswer && !isFinished" class="d-flex justify-center fixed-bottom right">
              <v-btn small variant="outlined" color="error" @click="handleDifficulty('Muito difícil', currentFlashcard.refId, currentFlashcard.easiness)">Muito<br>difícil</v-btn>
              <v-btn small variant="outlined" color="warning" @click="handleDifficulty('Difícil', currentFlashcard.refId, currentFlashcard.easiness)">Difícil</v-btn>
              <v-btn small variant="outlined" color="success" @click="handleDifficulty('Fácil', currentFlashcard.refId, currentFlashcard.easiness)">Fácil</v-btn>
              <v-btn small variant="outlined" color="info" @click="handleDifficulty('Muito fácil', currentFlashcard.refId, currentFlashcard.easiness)">Muito<br>fácil</v-btn>
            </v-card-actions>
            
            <v-card-actions v-if="!showAnswer && !isFinished" class="d-flex justify-center fixed-bottom right">
              <v-btn  block small variant="outlined" color="primary" @click="showAnswer = true">Mostrar Resposta</v-btn>
            </v-card-actions>        

          </v-col>
        </v-row>
      </div>
    </v-container>
  </div>
</template>


<script>
export default {
  data() {
    return {
      currentFlashcardIndex: 0,
      difficulties: ['Super Fácil', 'Fácil', 'Difícil', 'Super Difícil'],
      showAnswer: false,
      difficultyTracking: [] // Objeto para rastrear a dificuldade de cada card
    }
  },
  computed: {
    currentFlashcard() {
      return this.flashcards[this.currentFlashcardIndex];
    },
    isFinished() {
      return this.currentFlashcardIndex === null;
    },
    flashcards(){
      return this.$store.getters.flashcards
    },
    totalFlashcards() {
      return this.flashcards.length;
    },
    progressPercentage() {
      console.log((this.currentFlashcardIndex + 1) / this.totalFlashcards*100)
      return ((this.currentFlashcardIndex + 1) / this.totalFlashcards) * 100;
    }
  },
  methods: {
    handleDifficulty(difficulty, refId, easiness) {
      // Aqui você pode implementar a lógica para salvar a dificuldade do flashcard
      // Atualizar a easiness com base na dificuldade percebida
      // (Por exemplo, aumente ou diminua a easiness com base na dificuldade relatada)
      
      if (difficulty === 'Muito fácil') {
          easiness = easiness + 0.2;
      } else if (difficulty === 'Fácil') {
          easiness = easiness + 0.1;
      } else if (difficulty === 'Difícil') {
          easiness = easiness - 0.1;
      } else if (difficulty === 'Muito difícil') {
          easiness = easiness - 0.2;
      }
      // Calcular o próximo intervalo com base na easiness
      if (easiness < 1.3) {
          easiness = 1.3;
      }
      if (easiness > 3.4) {
          easiness = 3.4;
      }
      //nextInterval = interval * easiness;


      this.difficultyTracking.push({ refId, easiness });
      this.moveToNextFlashcard();
    },
    moveToNextFlashcard() {
      this.currentFlashcardIndex++;
      this.showAnswer = false;
      if (this.currentFlashcardIndex >= this.flashcards.length) {
        this.currentFlashcardIndex = null;
        console.log(this.difficultyTracking); // Ao final, você terá o rastreamento completo das dificuldades por refId
        console.log(this.difficultyTracking[0]); // Ao final, você terá o rastreamento completo das dificuldades por refId
      }
    },
    saveCards(){
      console.log(this.totalFlashcards)
      const payload = {
        total: this.totalFlashcards,
        difficultyTracking: this.difficultyTracking // Inclui o rastreamento de dificuldades no payload
      };
      const payload2 = {
        difficultyTracking: this.difficultyTracking // Inclui o rastreamento de dificuldades no payload
      };

      this.$store.dispatch('saveCards', payload)
        .then(() => {
          this.$router.push('/webapp/home');
        })
        .catch((error) => {
          console.error("Error submitting data:", error);
          // Handle error scenario here if needed
        });


      // console.log("salvando agora a dificuldade")
      
      // this.$store.dispatch('saveDifficulty', payload2)
      //   .then(() => {
      //   })
      //   .catch((error) => {
      //     console.error("Error submitting data: Difficulty", error);
      //     // Handle error scenario here if needed
      //   });  
    }
  }
}
</script>

<style scoped>
.flashcard {
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
}

.flashcard:hover {
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.15), 0 3px 6px rgba(0, 0, 0, 0.12);
}

.flashcard-content {
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 24px;
  
}

.progress-text {
  font-size: 14px;
  margin-bottom: 10px;
}

.text-h5 {
  font-size: 24px;
  font-weight: bold;
}

.text-h5-question {
  font-size: 24px;
}

.text-body-1 {
  .text-h5 {
  font-size: 24px;
  font-weight: bold;
}
}

.sobre {
  background-color: #f5f5f5;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.progress-bar {
  margin-bottom: 10px; /* Optional: Add margin to separate progress bar from content */
}
</style>
