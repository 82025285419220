<template>
    <v-app>
      <!-- Barra de navegação -->
      <v-app-bar app color="#144178">
        <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
        <v-img src="../assets/medhack_logo_branco_horiz.png" alt="Logo do MedHack" height="35" style="margin-top: 0px;" class="ml-4"></v-img>
        <v-spacer class="d-none d-md-flex"></v-spacer> <!-- Adicione um espaçador apenas em telas médias ou maiores -->
        <v-spacer class="d-none d-md-flex"></v-spacer> <!-- Adicione um espaçador apenas em telas médias ou maiores -->
        <v-spacer class="d-none d-md-flex"></v-spacer> <!-- Adicione um espaçador apenas em telas médias ou maiores -->
        <v-spacer class="d-none d-md-flex"></v-spacer> <!-- Adicione um espaçador apenas em telas médias ou maiores -->
        <v-spacer class="d-none d-md-flex"></v-spacer> <!-- Adicione um espaçador apenas em telas médias ou maiores -->
        <v-spacer class="d-none d-md-flex"></v-spacer> <!-- Adicione um espaçador apenas em telas médias ou maiores -->
        <v-spacer class="d-none d-md-flex"></v-spacer> <!-- Adicione um espaçador apenas em telas médias ou maiores -->

        <!-- Adicionando campo para o nome da pessoa à direita -->
        <v-spacer></v-spacer>
        <v-toolbar-items>
            <v-icon class="mr-2 mt-5">mdi-account-circle</v-icon>
          <v-toolbar-title class="mr-10 mt-5" style="font-size: 15px;">{{userName}}</v-toolbar-title>
        </v-toolbar-items>
      </v-app-bar>
  
      <!-- Barra lateral (navigation drawer) -->
      <v-navigation-drawer app v-model="drawer" color="#144178">
        <v-list dense>
          <v-list-item link to="/webapp/home">
            <v-list-item-icon>
              <v-icon>mdi-cards</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Decks</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- Botão para rota "Results" -->
          <v-list-item link to="/webapp/results">
            <v-list-item-icon>
              <v-icon>mdi-chart-bar</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Métricas</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
  
          <!-- Botão para rota "About" -->
          <v-list-item link to="/webapp/profile">
            <v-list-item-icon>
              <v-icon>mdi-account-edit</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Usuário</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          
          <!-- Botão para rota "About" -->
          <v-list-item link to="/webapp/about">
            <v-list-item-icon>
              <v-icon>mdi-information</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Info</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        
        
        <!-- Separador -->
        <v-divider></v-divider>
        
        <!-- Botão de logout (fixado na parte inferior) -->
        <v-list-item class="mt-auto" @click="logout">
          <v-list-item-icon>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-navigation-drawer>
  
      <!-- Conteúdo principal -->
      <v-main>
        <router-view></router-view>
      </v-main>
  
      <!-- Rodapé -->
      <v-footer app color="#144178">
        <span>&copy; 2024 MedHack</span>
      </v-footer>
    </v-app>
  </template>
  
  <script>
  export default {
    data() {
      return {
        drawer: false,
                componentMounted: false

      };
    },

    beforeMount() {
    // Load data before  mounting
    this.$store.dispatch("checkExistingUser");
    this.$store.dispatch("getCards");

    },

    computed:{
      userName: function () {
        // Verifica se this.$store.getters.userName não é undefined ou null
        if (this.$store.getters.userName !== undefined && this.$store.getters.userName !== null) {
            // Verifica se this.$store.getters.userName é um array e possui pelo menos um elemento
            if (Array.isArray(this.$store.getters.userName) && this.$store.getters.userName.length > 0) {
                // Retorna o nome do primeiro elemento do array
                return this.$store.getters.userName[0].firstName;
            } else {
                // Trate o caso em que this.$store.getters.userName é um array vazio
                return '';
            }
        } else {
            // Trate o caso em que this.$store.getters.userName é undefined ou null
            return '';
        }
    },
    },
    methods: {
      logout() {
        this.$store.dispatch('logout').then(() => {
           this.$router.push('/')
          }); 
             //console.log("Logout executado");
      },
      check() {
        this.$store.dispatch('checkExistingUser').then(() => {
          // this.$router.push('/')
          }); 
             //console.log("Logout executado");
      }
    }
  };
  </script>
  