import { createStore } from 'vuex';
import { signInWithEmailAndPassword, signOut, sendPasswordResetEmail, onAuthStateChanged } from 'firebase/auth';
import { auth, db } from '../main'; // importando auth do seu main.js
import { collection, query, where, getDocs, updateDoc, getDoc, doc } from 'firebase/firestore';



export default createStore({
  state: {
    user: null,
    loading: false,
    error: false,
    userName: [],
    areas:[],
    flashcards:[]
  },
  getters: {
    user(state) {
      return state.user;
    },
    loading(state) {
      return state.loading;
    },
    error(state) {
      return state.error;
    },
    userName (state){
      return state.userName
    },
    areas (state){
    return state.areas
    },
    flashcards(state){
    return state.flashcards
    }
  },
  mutations: {
    setUser(state, payload) {
      state.user = payload;
    },
    setLoading(state, payload) {
      state.loading = payload;
    },
    setError(state, payload) {
      if(payload.code == "auth/wrong-password"){
        payload.message = "Senha Incorreta."
      } else if(payload.code == "auth/network-request-failed"){
        payload.message = "Sem conexão."
      } else if(payload.code == "auth/user-not-found"){
        payload.message = "Usuário não encontrado."
      } else if(payload.code == "auth/too-many-requests"){
        payload.message = "Muitas tentativas incorretas. O acesso foi bloqueado temporariamente. Aguarde e tente novamente"
      } else if(payload.code == "auth/invalid-credential"){
        payload.message = "Verifique E-mail ou Senha"
      } else if(payload.code == "auth/invalid-login-credential"){
        payload.message = "Verifique E-mail ou Senha"
      } else {
        payload.message = "Verifique E-mail ou Senha"
      } 
      state.error = payload   
    },
    setUserName(state, payload){
      state.userName = payload
    },
    clearUserName (state){
      state.userName = ""
    },
    setAreas(state, payload){
      state.areas = payload
    }, 
    clearAreas(state){
      state.areas = []
    },   
    setFlashcards(state, payload){
      state.flashcards = payload
    },    
    clearFlashcards(state){
      state.flashcards = []
    },  
    clearError(state) {
      state.error = false;
    },
  },
  actions: {
    // Suas ações Vuex aqui
    async signUserIn({ commit }, { email, password }) {
      try {
       // console.log( auth );
          
        // Autentique o usuário com e-mail e senha
        const userCredential = await signInWithEmailAndPassword(auth, email, password);
        
        // Extraia o usuário da credencial
        const user = userCredential.user;

        // Chame a mutação setUser para atualizar o estado do usuário
        console.log("isso")
        console.log(user)
        commit('setUser', user);
        commit('clearError')
        // Retorne o usuário autenticado para outros fins, se necessário
        return user;
      } catch (error) {
        // Manipule os erros, se ocorrerem
        console.error('Erro ao fazer login:', error.message);
        commit('setLoading', false)
        commit('setError', error)
      }
    },
    
    logout({ commit }) {
      try {
        // Autentique o usuário com e-mail e senha
         signOut(auth);        
        // Extraia o usuário da credencial
        // Chame a mutação setUser para atualizar o estado do usuário
        //commit('setUser', user);
        commit('setUser', null);
        commit('setLoading', false);
        console.log("Logout executado");
        
      } catch (error) {
        // Manipule os erros, se ocorrerem
        console.error('Erro ao fazer logout:', error.message);
        throw error;
      }
    },

    async forgotPassword({ commit }, payload) {
      try {
        //console.log( auth );        
        // Autentique o usuário com e-mail e senha
        const userCredential = await sendPasswordResetEmail(auth, payload.email);
        console.log("Email enviado.")
        commit('clearError')
        // Retorne o usuário autenticado para outros fins, se necessário
      } catch (error) {
        // Manipule os erros, se ocorrerem
        console.error('Erro ao mudar a senha:', error.message);
        commit('setLoading', false)
        commit('setError', error)
      }
    },

    autoSignIn({ commit }, payload) {
      if (payload) {
        commit('setUser', { email: payload.email });
      }
    },

    async listenToAuthChanges({ commit }) {
      onAuthStateChanged(auth, (user) => {
        if (user) {
          commit('setUser', { email: user.email });
        } else {
          commit('setUser', null);
        }
      });
    },

    //action to see users name
    async checkExistingUser({ commit }) {
      try {
        console.log(auth.currentUser.email)
        const usersRef = collection(db, 'users'); // Referência para a coleção 'users'
        const q = query(usersRef, where('email', '==', auth.currentUser.email)); // Consulta para buscar usuários com o email especificado
        const querySnapshot = await getDocs(q); // Obtém todos os documentos que correspondem à consulta
        const userDataArray = []; // Array para armazenar os dados dos usuários
        querySnapshot.forEach((doc) => {
          // Para cada documento, imprime apenas o nome do usuário
          console.log('Nome do usuário:', doc.data().name);
          userDataArray.push({firstName: doc.data().name, 
            lastName: doc.data().lastName,
            userEmail: doc.data().email,
            userPhone: doc.data().phone,
            userAge: doc.data().age,
            userGender: doc.data().gender,
            totalCards: doc.data().totalCards,
            dates: doc.data().dates,
            demo: doc.data().demo,

             });
          console.log(userDataArray)
          commit('setUserName',userDataArray)
        });
      } catch (error) {
        console.error('Erro ao buscar usuários:', error.message);
        throw error;
      }
    
    },

    // Action to SAVE Profile data.
    async saveProfile ({commit}, payload){
      try {
        // Adicione email e ação ao payload, se necessário
        payload.email = auth.currentUser.email;
        // Referência para a coleção 'users'
        const usersRef = collection(db, 'users');
        console.log(payload.email)

        // Consulta para buscar usuários com o email especificado
        const q = query(usersRef, where('email', '==', payload.email));
    
        // Obtenha os dados do usuário
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach(async (doc) => {
          // Atualize os campos do usuário com os dados do payload
          await updateDoc(doc.ref, {
            name: payload.name,
            lastName: payload.lastName,
            email: payload.email,
            phone: payload.phone,
            age: payload.age,
            gender: payload.gender
            // Adicione mais campos conforme necessário
          });
          const userDataArray = []; // Array para armazenar os dados dos usuários

          userDataArray.push({
            firstName: payload.name, 
            lastName: payload.lastName,
            userEmail: payload.email,
            userPhone: payload.phone,
            userAge: payload.age,
            userGender: payload.gender,
             });
          console.log(userDataArray)
          commit('setUserName',userDataArray)
        });
        // commit('profileSaved', payload);
      } catch (error) {
        console.error('Erro ao salvar perfil:', error);
        // Realize uma ação de tratamento de erro adequada, se necessário
      }
    },

     // Action to SAVE CARDS TO USERS data.
     async saveCards ({commit}, payload){
      try {
        // Adicione email e ação ao payload, se necessário
        payload.email = auth.currentUser.email;
        // Referência para a coleção 'users'
        const usersRef = collection(db, 'users');
        console.log(payload.email)
        //pega dado do vuex
        const userDataArray = this.state.userName; // Array para armazenar os dados dos usuários

        const currentDate = new Date();
        // Extract day, month, and year from the current date
        const day = currentDate.getDate();
        const month = currentDate.getMonth() + 1; // Month is zero-based, so add 1
        const year = currentDate.getFullYear();
        const currentDateStr = day + "/" + month + "/" + year;
        //const currentDateStr = "20" + "/" + month + "/" + year;

        console.log("Procurando por data:", currentDateStr);
        console.log(userDataArray[0]);

        // Verifica se a data atual está presente no array de datas
        let found = false;
        if (userDataArray.length > 0 && userDataArray[0].dates && Array.isArray(userDataArray[0].dates)) {
          let foundIndex = -1;
          userDataArray[0].dates.forEach((dateObj, index) => {
              if (dateObj.date === currentDateStr) {
                  console.log("Encontrado");
                  foundIndex = index;
                  dateObj.cards += payload.total; // Atualiza o valor de cards
                  return; // Sai do loop forEach
              }
          });

          if (foundIndex !== -1) {
              // Se encontrou a data, atualiza apenas o objeto de data encontrado
              payload.dates = [...userDataArray[0].dates]; // Clona o array de datas
          } else {
              console.log("Não encontrado");
              // Se não encontrou, adiciona uma nova entrada ao array de datas
              payload.dates = [
                  ...userDataArray[0].dates,
                  {
                      date: day + "/" + month + "/" + year,
                      //date: "20" + "/" + month + "/" + year,
                      cards: payload.total
                  }
              ];
            }
        }
        else{
          console.log("userDataArray nao encontrado")
          const date = [] //cria o array.
          date.push({
            date: day+"/"+month+"/"+year, 
            cards: payload.total,
            });
          payload.dates = date;
          console.log("test",payload.dates)
          userDataArray[0].dates = date
          
        }

        // Check if userDataArray exists and userDataArray[0].totalCards is undefined
        if (userDataArray.length > 0 && userDataArray[0].totalCards === undefined) {
          console.log("userDataArray[0].totalCards is undefined");
          userDataArray[0].totalCards = 0
        } else {
          console.log("userDataArray[0].totalCards is defined");
            // Now you can proceed with your code logic
        }
        // Consulta para buscar usuários com o email especificado
        const q = query(usersRef, where('email', '==', payload.email));
    
        // Obtenha os dados do usuário
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach(async (doc) => {
          // Atualize os campos do usuário com os dados do payload
          await updateDoc(doc.ref, {
            totalCards:  userDataArray[0].totalCards + payload.total ,
            dates: payload.dates
            // Adicione mais campos conforme necessário
          });
         // console.log(userDataArray)      
          userDataArray[0].totalCards = userDataArray[0].totalCards + payload.total

          console.log("debugging.")
          console.log(userDataArray)
          commit('setUserName',userDataArray)
        });
        // commit('profileSaved', payload);
      } catch (error) {
        console.error('Erro ao salvar perfil:', error);
        // Realize uma ação de tratamento de erro adequada, se necessário
      }
    },

    async saveDifficulty({ commit }, payload) { //revisar tudoooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooo
      console.log('executando......')
      try {
        // Referência para a subcoleção "GO15" dentro da coleção "flashcards/decks"
        const go15SubcollectionRef = collection(db, 'flashcards', 'decks', 'GO15');
    
        // Verificando se há documentos dentro da subcoleção "GO15"
        const go15SubcollectionSnapshot = await getDocs(go15SubcollectionRef);
        if (!go15SubcollectionSnapshot.empty) {
          console.log('Existem documentos dentro da subcoleção GO15.');
          // Se houver documentos, significa que há uma subcoleção dentro do documento GO15.
        } else {
          console.log('Não existem documentos dentro da subcoleção GO15.');
          // Se não houver documentos, significa que não há subcoleção dentro do documento GO15.
        }
      } catch (error) {
        console.error('Erro ao salvar os cards:', error);
        // Tratar o erro conforme necessário
      }
    },




    //action to get Cards Theme
    async getCards({ commit }) {
      // Adicione variáveis para manter as contagens das propriedades
      try {
        if (auth.currentUser) {
          const userEmail = auth.currentUser.email;
          console.log(userEmail);

          // Referência para o documento 'decks' dentro da coleção 'flashcards'
          const decksDocRef = doc(db, 'flashcards', 'decks');

          // Obtenha o documento 'decks'
          const decksDocSnap = await getDoc(decksDocRef);

          let areas = [];

          // Verifique se o documento 'decks' existe
          if (decksDocSnap.exists()) {
            // Lista de subcoleções a serem verificadas
            const subcollections = ['GO', 'PED', 'CIR', 'CM', 'PSI', 'OFT', 'MFC', 'ORT'];

            // Itere sobre as subcoleções
            for (const subcollection of subcollections) {
              // Referência para a subcoleção atual dentro do documento 'decks'
              const subcollectionRef = collection(decksDocRef, subcollection);

              // Obtenha os documentos dentro da subcoleção atual
              const docsSnap = await getDocs(subcollectionRef);

              // Itere sobre os documentos
              for (const docSnap of docsSnap.docs) {
                // Acesse cada documento
                const docData = docSnap.data();
                
                // Verifique se a propriedade 'area' existe no documento
                if (docData.area) {
                  let area = areas.find(item => item.nome === docData.area);

                  if (!area) {
                    area = {
                      nome: docData.area,
                      expanded: false,
                      subareas: [],
                      count: 0 // Inicialize a contagem para a área
                    };
                    areas.push(area);
                  }

                  let subarea = area.subareas.find(item => item.nome === docData.subArea);

                  if (!subarea) {
                    subarea = {
                      nome: docData.subArea,
                      expanded: false,
                      temas: [],
                      count: 0 // Inicialize a contagem para a subárea
                    };
                    area.subareas.push(subarea);
                  }

                  let tema = subarea.temas.find(item => item.nome === docData.theme);

                  if (!tema) {
                    tema = {
                      nome: docData.theme,
                      expanded: false,
                      subtemas: [],
                      count: 0 // Inicialize a contagem para o tema
                    };
                    subarea.temas.push(tema);
                  }

                  // Verifique se a propriedade 'subTheme' existe no documento
                  if (docData.subTheme) {
                    let subtema = tema.subtemas.find(item => item.nome === docData.subTheme);

                    if (!subtema) {
                      subtema = {
                        nome: docData.subTheme,
                        count: 0 // Inicialize a contagem para o subtema
                      };
                      tema.subtemas.push(subtema);
                    }
                    // Incrementar a contagem para o subtema
                    subtema.count++;
                  }

                  // Incrementar a contagem para cada propriedade encontrada
                  area.count++;
                  subarea.count++;
                  tema.count++;
                }
              }
            }
          } else {
            console.log('Documento "decks" não encontrado');
          }

          // Exiba as áreas encontradas
          //console.log('Áreas encontradas:', areas);

          // Atualize o estado Vuex com as áreas e as contagens
          commit('setAreas', areas);
        } else {
          console.log('Usuário não autenticado');
        }
      } catch (error) {
        console.error('Erro ao buscar as áreas:', error.message);
        throw error;
      }
    },

    // Função para embaralhar um array usando o algoritmo de Fisher-Yates
    shuffleArray(array) {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
      return array;
    },

    //Action for generate Cards to the quiz.
    async generateCards({ commit }, payload) {
      try {
            const deckDocRef = doc(db, 'flashcards', 'decks');
            let querySnapshot;  
            const cards = [];

            const subcollections = ['GO', 'PED', 'CIR', 'CM', 'PSI', 'OFT', 'MFC', 'ORT'];

            for (const subcollection of subcollections) {
              if (payload.subArea) {
                console.log("subarea")
                if(payload.tema){
                  console.log("tema")
                  if(payload.subTema){
                    console.log("subtema")
                    querySnapshot = await getDocs(
                      query(collection(deckDocRef, subcollection),
                            where('area', '==', payload.area),
                            where('subArea', '==', payload.subArea),
                            where('theme', '==', payload.tema),
                            where('subTheme', '==', payload.subTema)
                      )                    
                  );
                  }else{
                    querySnapshot = await getDocs(
                        query(collection(deckDocRef, subcollection),
                              where('area', '==', payload.area),
                              where('subArea', '==', payload.subArea),
                              where('theme', '==', payload.tema)
                        )
                    );
                  }
                }

                else {
                  querySnapshot = await getDocs(
                    query(collection(deckDocRef, subcollection),
                          where('area', '==', payload.area),
                          where('subArea', '==', payload.subArea)
                    )
                  );
                }
            }
            else {
              console.log("area")

                  querySnapshot = await getDocs(
                      query(collection(deckDocRef, subcollection),
                            where('area', '==', payload.area)
                      )
                  );
            }

              querySnapshot.forEach(doc => {
              const data = doc.data();
              cards.push({
                question: data.question,
                answer: data.answer,
                questionImage: data.questionImage || null,
                answerImage: data.answerImage || null,
                refId: data.refId,
                easiness: data.easiness

              });
            });
          }
          // Função para embaralhar um array usando o algoritmo de Fisher-Yates
            function shuffleArray(array) {
              for (let i = array.length - 1; i > 0; i--) {
                const j = Math.floor(Math.random() * (i + 1));
                [array[i], array[j]] = [array[j], array[i]];
              }
              return array;
            }
          // Embaralhar os cartões
            const shuffledCards = shuffleArray(cards);
            // Limitar os cartões para no máximo 10 após embaralhar
            const limitedAndShuffledCards = shuffledCards.slice(0, 10);        
            console.log(limitedAndShuffledCards);
            // Atualize o estado Vuex com as áreas e as contagens
            commit('setFlashcards', limitedAndShuffledCards);
            // Aqui você pode fazer o que quiser com os cards, como passá-los para o estado Vuex ou qualquer outra coisa
      } catch (error) {
          console.error('Erro ao buscar os cards:', error.message);
      }
  },

    //Action for cleaning the error state.
    clearError ({commit}) {
      commit('clearError')
    },
  },
  modules: {
    // módulos adicionais aqui
  },
});